@import url(https://fonts.googleapis.com/css?family=Dancing+Script&display=swap);
body, html {
  margin: 0;
  height: 100%;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  display: flex;
  justify-content: center;
}

.bgContainer {
  z-index: -1;
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.bg {
  /* Full height */
  min-width: 100%;
  min-height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
}

.contentFixtureContainer {
  z-index: -1;
  position: fixed;
  top: 95%;
  bottom: 0px;
  left: 0px;
  right: 0px;

  background-color: rgb(223, 15, 8);
  box-shadow: 0px -2px 25px 0px rgba(0,0,0,0.54);
}

.contentContainer {
  z-index: 100;
  background-color: white;
  width: 800px;
  border-radius: 40px;
  box-shadow: 0px -2px 25px 0px rgba(0,0,0,0.54);
}

.contentContainer > h1 {
  font-size: 72px;
  padding: 30px;

  font-family: 'Dancing Script', cursive;
}

.itemContainer {
  padding-bottom: 120px;
}

.itemContainer > img {
  width: 100px;
}

.itemContainer h1 {
  font-size: 36px;
}


@media only screen and (max-width: 900px) {
  .contentContainer {
    z-index: 100;
    background-color: white;
    width: 100vw;
    border-radius: 40px;
  }
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

